import React from 'react';
import scrollDelay from "./src/gatsby/browser/shouldUpdateScroll"
import CustomLayout from "./src/gatsby/browser/wrapPageElement"
import RootElement from "./src/gatsby/browser/wrapRootElement"

export const wrapPageElement = CustomLayout

export const wrapRootElement = ({ element }) => {
  return <RootElement>{element}</RootElement>;
};

export const shouldUpdateScroll = scrollDelay

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `Our website has been updated. ` +
    `Reload to display the latest version?`
  )

  if (answer === true) {
    window.location.reload()
  }
}