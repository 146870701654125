import * as React from "react"
import { Link } from "gatsby"
import { whatLinks, artistLinks, whoLinks } from "../data/links"
import styled from "@emotion/styled"
import { colors, weights, container, fonts, mediaQueries } from "../styles"
import SocialProfiles from "./SocialShareContainer/SocialProfiles"

const FooterStyles = styled.footer`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${colors.brBlue3};
  width: 100%;
  z-index: 100;

  .container {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      a {
        transition: color 0.3s ease;
      }
    }
  }
  ${mediaQueries.phoneLarge} {
    .container {
      align-items: center;
      justify-content: space-around;
      flex-direction: row;
    }
  }
`

const FooterMenuStyles = styled.div`
  ${mediaQueries.phoneLarge} {
    width: 20%;
    :nth-of-type(1) {
      text-align: right;
    }
    :nth-of-type(2) {
      text-align: center;
    }
    :nth-of-type(3) {
      text-align: left;
    }
    h5 {
      padding-top: 0;
      margin-bottom: 0;
    }
  }

  width: 100%;
  text-align: center;
  h5 {
    font-family: ${fonts.sans};
    font-weight: ${weights.light};
    color: ${colors.hmLight};
    margin-top: 20px;
    margin-bottom: 10px;
  }
  ul {
    font-size: 0.85028rem;
    display: flex;
    flex-direction: column;
    li {
      margin-bottom: 5px;
      &:last-of-type {
        margin-bottom: 8px;
      }

      a {
        color: ${colors.hmLight};
        text-decoration: none;
        text-transform: capitalize;
        font-weight: ${weights.bold};
      }

      &:hover,
      &:focus {
        a {
          color: ${colors.brBlue};
        }
      }
    }
    ${mediaQueries.phoneLarge} {
    }
  }
`

const CopyrightStyles = styled.div`
  color: ${colors.hmLight};
  margin-bottom: 40px;
  ${mediaQueries.phoneLarge} {
    margin-bottom: 60px;
  }

  .container__tight {
    width: 60%;
    display: block;
    margin: 0 auto;
    text-align: center;
    border-top: solid 1px ${colors.hmLight};
    margin-top: 10px;
    p {
      font-size: 12px;
      font-weight: ${weights.light};
    }
    ${mediaQueries.phoneLarge} {
      margin-top: 30px;
      p {
        margin-top: 10px;
      }
    }
  }
  
    .container__company {
    width: 100%;
    display: block;
    margin: 0 auto;
    text-align: center;
    border-top: solid 1px ${colors.hmLight};
    margin-top: 10px;
    p {
      font-size: 14px;
      font-weight: ${weights.light};
    }
    ${mediaQueries.phoneLarge} {
      margin-top: 30px;
      p {
        margin-top: 10px;
      }
    }
  }
`

const NewFooter = () => {
  return (
    <>
      <FooterStyles>
        <SocialProfiles color />

        <div className="container" css={container.max}>
          <FooterMenuStyles>
            <h5>What We Do</h5>
            <ul>
              {whatLinks.map((item, index) => {
                return (
                  <li key={index}>
                    <Link to={item.path}>{item.text}</Link>
                  </li>
                )
              })}
            </ul>
          </FooterMenuStyles>

          <FooterMenuStyles>
            <h5>Artist Management</h5>
            <ul>
              {artistLinks.map((item, index) => {
                return (
                  <li key={index}>
                    <Link to={item.path}>{item.text}</Link>
                  </li>
                )
              })}
            </ul>
          </FooterMenuStyles>

          <FooterMenuStyles>
            <h5>Who We Are</h5>
            <ul>
              {whoLinks.map((item, index) => {
                return (
                  <li key={index}>
                    <Link to={item.path}>{item.text}</Link>
                  </li>
                )
              })}
            </ul>
          </FooterMenuStyles>
        </div>

        <CopyrightStyles>
          <div className="container__company">
            <p>
              <strong>LocksBridge Artist Management LLC</strong>
              <br />
              855 Boylston St,
              <br />
              Boston, MA 02116,
              <br />
              United States
            </p>
          </div>
          <div className="container__tight">
            <p>
              2024-2025 © LocksBridge Artist Management. All Rights Reserved.
            </p>
          </div>
        </CopyrightStyles>
      </FooterStyles>
    </>
  )
}

export default NewFooter
