import { useState, useEffect } from "react"

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    widthQuarter: undefined,
  })

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        widthQuarter: window.innerWidth * 0.75,
      })
    }

    window.addEventListener("resize", handleResize)

    handleResize()

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowSize
}

export function useWindow() {
  const [windowSize, setWindowSize] = useState(() => {
    return (
      typeof window !== "undefined" && {
        width: window.innerWidth,
        height: window.innerHeight,
      }
    )
  })

  useEffect(() => {
    const onResize = () =>
      typeof window !== "undefined" &&
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    if (typeof window !== "undefined") {
      window.addEventListener("resize", onResize)
    }
    return () =>
      typeof window !== "undefined" &&
      window.removeEventListener("resize", onResize)
  })

  return windowSize
}
