import React, { useState } from "react"
import { motion } from "framer-motion"
import { whatLinks, artistLinks, whoLinks } from "../../../data/links"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { colors, fonts, weights } from "../../../styles";

const Container = styled(motion.nav)`
  height: 10vh;
  overflow-y: hidden;
  display: flex;
  justify-content: space-between;
  padding: 35px 45px;
  font-size: 16px;
  font-family: ${fonts.sans};

  .logo-container {
    overflow-y: hidden;

    h1 {
      text-transform: uppercase;
      font-size: 15px;
    }
  }

  .menu-container {
    overflow-y: hidden;

    div {
      text-transform: uppercase;
      font-size: 13px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    z-index: 9999999;

    button {
      align-self: flex-end;
      margin: 35px 45px 0 0;
      outline: none;
      border: none;
      background-color: transparent;
      text-transform: uppercase;
      font-family: inherit;
      font-size: 13px;
      color: white;

      &:hover {
        cursor: pointer;
      }
    }

    ul {
      list-style: none;
      margin-top: 40px;
      padding: 0;
      li {
        margin: 0;
        overflow-y: hidden;
        user-select: none;

        .menu-header {
          text-align: center;
          color: ${colors.BlueLight};
          font-size: 1rem;
          font-weight: ${weights.light};
          margin-bottom: 10px;
          margin-top: 20px;
        }

        .link {
          text-align: center;
          color: ${colors.whiteForBlue};
          font-size: 1.3rem;
          font-weight: ${weights.medium};
          letter-spacing: 0.01rem;
          margin-bottom: 5px;
        }
        
        .link > a {
          color: inherit;
          text-decoration: none;
        }

        &:hover {
          font-style: italic;
          cursor: pointer;
        }
      }
    }

    .contact {
      margin-top: 80px;
      display: flex;

      h5 {
        font-weight: normal;

        &:first-of-type {
          margin-right: 40px;
        }
      }
    }
  }
`

const NavBarFramer = () => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false)

  const hideNavItemsVariant = {
    opened: {
      opacity: 0,
      y: "-100%",
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
    closed: {
      opacity: 1,
      y: "0%",
      transition: {
        delay: 1.1,
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  }

  const mobileMenuVariant = {
    opened: {
      y: "0%",
      transition: {
        delay: 0.15,
        duration: 1.1,
        ease: [0.74, 0, 0.19, 1.02],
      },
    },
    closed: {
      y: "-100%",
      transition: {
        delay: 0.35,
        duration: 0.63,
        ease: [0.74, 0, 0.19, 1.02],
      },
    },
  }

  const fadeInVariant = {
    opened: {
      opacity: 1,
      transition: {
        delay: 1.2,
      },
    },
    closed: { opacity: 0 },
  }

  const ulVariant = {
    opened: {
      transition: {
        delayChildren: 1,
        staggerChildren: 0.18,
      },
    },
    closed: {
      transition: {
        staggerChildren: 0.06,
        staggerDirection: -1,
      },
    },
  }

  const liVariant = {
    opened: {
      opacity: 1,
      y: "0%",
      transition: {
        duration: 0.65,
        ease: "easeOut",
      },
    },
    closed: {
      opacity: 0,
      y: "100%",
      transition: {
        duration: 0.25,
        ease: "easeInOut",
      },
    },
  }

  return (
    <>
      <Container initial="closed" animate={mobileNavOpen ? "opened" : "closed"}>
        <div className="logo-container">
          <motion.h1 variants={hideNavItemsVariant}>Design Agency</motion.h1>
        </div>

        <div className="menu-container">
          <motion.div
            variants={hideNavItemsVariant}
            onClick={() => setMobileNavOpen(true)}
          >
            Menu
          </motion.div>
        </div>

        <motion.div variants={mobileMenuVariant} className="mobile-menu">
          <motion.button
            variants={fadeInVariant}
            onClick={() => setMobileNavOpen(false)}
          >
            Close
          </motion.button>

          <motion.ul variants={ulVariant}>
            <motion.li whileTap={{ scale: 0.95 }}>
              <motion.div variants={liVariant} className="menu-header">What We Do</motion.div>
            </motion.li>

            {whatLinks.map(({ path, text }, index) => (
              <motion.li whileTap={{ scale: 0.95 }}>
                <motion.div variants={liVariant} className="link">
                  <Link to={path} key={index}>
                    {text}
                  </Link>
                </motion.div>
              </motion.li>
            ))}

            <motion.li whileTap={{ scale: 0.95 }}>
              <motion.div variants={liVariant} className="menu-header">Artist Management</motion.div>
            </motion.li>

            {artistLinks.map(({ path, text }, index) => (
              <motion.li whileTap={{ scale: 0.95 }}>
                <motion.div variants={liVariant} className="link">
                  <Link to={path} key={index}>
                    {text}
                  </Link>
                </motion.div>
              </motion.li>
            ))}

            <motion.li whileTap={{ scale: 0.95 }}>
              <motion.div variants={liVariant} className="menu-header">Who We Are</motion.div>
            </motion.li>

            {whoLinks.map(({ path, text }, index) => (
              <motion.li whileTap={{ scale: 0.95 }}>
                <motion.div variants={liVariant} className="link">
                  <Link to={path} key={index}>
                    {text}
                  </Link>
                </motion.div>
              </motion.li>
            ))}
          </motion.ul>

          <motion.div variants={fadeInVariant} className="contact">
            <h5>+852 5650 2233</h5>
            <h5>hi@designagency.com</h5>
          </motion.div>
        </motion.div>
      </Container>
    </>
  )
}

export default NavBarFramer
