import React from "react"
import styled from "@emotion/styled"
import {
  FacebookIcon,
  TwitterIcon,
  LinkedInIcon,
  InstagramIcon,
  YouTubeIcon,
  MailToIcon,
} from "./icons"
import { motion } from "framer-motion"
import { mediaQueries } from "../../styles";

const SocialProfiles = ({ color }) => {
  const Row = styled(motion.div)`
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    margin: 30px 0;
    ${mediaQueries.phoneLarge} {
      margin: 40px 0;
    }
  `

  const ShareButton = styled(motion.a)`
    svg {
      width: 18px;
      height: 18px;
    }
    margin: 0 20px;

    display: flex;
    align-items: center;
    justify-content: center;
  `

  return (
    <Row>
      <ShareButton
        whileHover={{ scale: 1.1, opacity: 1 }}
        whileTap={{ scale: 0.9, opacity: 0.6 }}
        transition={{ duration: 0.2, ease: "easeOut", type: "tween" }}
        initial={{
          opacity: 0.9,
          scale: 1,
          fill: color ? "#ffffff" : "#73737D",
        }}
        href="https://www.instagram.com/locksbridgenet/"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="LocksBridge Artist Management Instagram Page"
      >
        <InstagramIcon fill={color ? "#ffffff" : "#73737D"} />
      </ShareButton>

      <ShareButton
        whileHover={{ scale: 1.1, opacity: 1 }}
        whileTap={{ scale: 0.9, opacity: 0.6 }}
        transition={{ duration: 0.2, ease: "easeOut", type: "tween" }}
        initial={{
          opacity: 0.9,
          scale: 1,
          fill: color ? "#ffffff" : "#73737D",
        }}
        href="https://www.facebook.com/locksbridgenet/"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="LocksBridge Artist Management Facebook Page"
      >
        <FacebookIcon fill={color ? "#ffffff" : "#73737D"} />
      </ShareButton>

      <ShareButton
        whileHover={{ scale: 1.1, opacity: 1 }}
        whileTap={{ scale: 0.9, opacity: 0.6 }}
        transition={{ duration: 0.2, ease: "easeOut", type: "tween" }}
        initial={{
          opacity: 0.9,
          scale: 1,
          fill: color ? "#ffffff" : "#73737D",
        }}
        href="https://www.linkedin.com/company/locksbridgenet/"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="LocksBridge Artist Management LinkedIn Page"
      >
        <LinkedInIcon fill={color ? "#ffffff" : "#73737D"} />
      </ShareButton>

      <ShareButton
        whileHover={{ scale: 1.1, opacity: 1 }}
        whileTap={{ scale: 0.9, opacity: 0.6 }}
        transition={{ duration: 0.2, ease: "easeOut", type: "tween" }}
        initial={{
          opacity: 0.9,
          scale: 1,
          fill: color ? "#ffffff" : "#73737D",
        }}
        href="https://www.youtube.com/@locksbridgenet"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="LocksBridge Artist Management YouTube Page"
      >
        <YouTubeIcon fill={color ? "#ffffff" : "#73737D"} />
      </ShareButton>

      <ShareButton
        whileHover={{ scale: 1.1, opacity: 1 }}
        whileTap={{ scale: 0.9, opacity: 0.6 }}
        transition={{ duration: 0.2, ease: "easeOut", type: "tween" }}
        initial={{
          opacity: 0.9,
          scale: 1,
          fill: color ? "#ffffff" : "#73737D",
        }}
        href="https://twitter.com/locksbridgenet"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="LocksBridge Artist Management Twitter Page"
      >
        <TwitterIcon fill={color ? "#ffffff" : "#73737D"} />
      </ShareButton>

      <ShareButton
        whileHover={{ scale: 1.1, opacity: 1 }}
        whileTap={{ scale: 0.9, opacity: 0.6 }}
        transition={{ duration: 0.2, ease: "easeOut", type: "tween" }}
        initial={{
          opacity: 0.9,
          scale: 1,
          fill: color ? "#ffffff" : "#73737D",
        }}
        href="mailto:info@locksbridge.net"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="LocksBridge Artist Management Twitter Page"
      >
        <MailToIcon fill={color ? "#ffffff" : "#73737D"} />
      </ShareButton>
    </Row>
  )
}
export default SocialProfiles
