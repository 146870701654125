import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/react"
import Menu from "../Menu"
import { colors, mediaQueries, jsBreakpoints, container } from "../../styles"
import { AppContext } from "../../gatsby/browser/AppProvider"
import useWindowSize from "../../hooks/useWindowSize"

import LBLogo from "./svg/LBLogo"
import LocksBridge from "./svg/LocksBridge"
import Hamburger from "./svg/hamburger"


const TopNav = () => {
  const { width } = useWindowSize()

  return (
    <>
      <AppContext.Consumer>
        {({ isNavOpen, handleNav }) => {
          return (
            <>

              <div
                css={[container.max, css`
                    position: ${isNavOpen ? "fixed" : "absolute"};
                    top: 0;
                    padding-top: 20px;
                    padding-bottom: 10px;
                    left: 50%;
                    transform: translateX(-50%);
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    z-index: 4;

                    ${mediaQueries.phoneLarge} {
                      padding-top: 30px;
                    }
                  `]}
              >
                <Link
                  to="/"
                  aria-label="locksbridge-artist-management-homepage"
                  data-cy="homeButton"
                >
                  {width > jsBreakpoints.phoneLarge ? (
                    <LocksBridge
                      css={css`
                        height: 60px;
                        fill: ${colors.lightgray};
                      `}
                    />
                  ) : (
                    <LBLogo
                      css={css`
                        fill: ${colors.lightgray};
                        height: 55px;
                        margin-left: 0;
                        margin-top: 5px;
                      `}
                    />
                  )}
                </Link>

                <button
                  css={css`
                    background-color: transparent;
                    padding: 0;
                    margin: 0;
                    border: none;
                    min-height: 25px;
                    cursor: pointer;

                    :focus {
                      outline: none;
                    }
                  `}
                  type="button"
                  onClick={() => handleNav()}
                  data-cy="menuButton"
                  aria-label="locksbridge-artist-management-menu"
                >
                  <Hamburger isOpen={isNavOpen} />
                </button>
              </div>
              <Menu toggleOpen={handleNav} menuOpen={isNavOpen} />
            </>
          )
        }}
      </AppContext.Consumer>
    </>
  )
}

export default TopNav
