import React from "react"
import { globalStyles } from "../../styles"
import { Global } from "@emotion/react"
import TransitionCurtain from "../../components/AdvancedDesignAssets/TransitionCurtain/TransitionCurtain";

const CustomLayout = ({ element, props }) => {
  return (
    <>
      <Global styles={globalStyles} />
      <TransitionCurtain {...props}>{element}</TransitionCurtain>
    </>
  )
}

export default CustomLayout
