/* eslint-disable import/prefer-default-export */
/* Variables and functions specifically for CSS-in-JS use */

/* Media queries */
const breakpoints = [480, 767, 900, 1220];
const names = [`xs`, `phoneLarge`, `tablet`, `desktop`];

export const mediaQueries = breakpoints.reduce((acc, bp, i) => {
  acc[names[i]] = `@media (min-width: ${bp}px)`;
  return acc;
}, {});

export const jsBreakpoints = breakpoints.reduce((acc, bp, i) => {
  acc[names[i]] = bp;
  return acc;
}, {});

export const mediaQueriesMax = breakpoints.reduce((acc, bp, i) => {
  acc[names[i]] = `@media (max-width: ${bp}px)`;
  return acc;
}, {});

/* Fonts */
export const fonts = {
  serif: `'Canela', serif`,
  sans: `'Inter', sans-serif` // only italic for medium
};

export const weights = {
  light: `200`,
  regular: `400`,
  medium: `600`,
  bold: `800`
};

/* Colors - Use a RGB to HEX converter */
export const colors = {
  white: `#ffffff`,
  whiteFaded: `#e7e5e4`,
  black: `#000`,
  gray: `#e0e0e0`,
  tagGray: `#282829`,
  lightgray: `#F8F8F9;`,
  darkgray: `#29292a`,
  darkgrayFaded: `rgba(41, 41, 42, 0.7)`,
  darkergrayFaded: `rgba(41, 41, 42, 0.9)`,
  reallydarkgray: `#282829`,
  yellow: `#e1c5a6`,
  lightblue: `#e7eff6`,
  blue: `#63ace5`,
  lbGreen:'#543A1E',
  lightgreen: `#cef5e2`,
  gatsbyPurple: '#663399',
  drupalBlue: '#29A8DF',
  drupal9Blue: '#009DE4',
  shopifyGreen: '#80AB42',
  bigCommerceBlue: '#0D52FF',
  lbColor: `rgba(16, 18, 22,0.2)`,
  lbPastelRed: '#98676b',
  lbWhite: '#f4ebe2',
  lbMain: '#3f3f3f',
  lbBrown: '#bcb0a7',
  lbGray: '#595959',
  lbBurgundy: '#9b5555',

  // new colors
  mcDark: '#2a2933',
  mcLight: '#e3e4e7',
  mcBlue: '#3569b2',

  // two accents

  hmLight: '#dacccb',
  hmLighter: '#ece5e4',
  hmBlue: '#00b6b4',
 /* hmRed: '#ec5747',
  hmRedLighter: '#e8695b',
  */

  brBlue: '#0000ff',
  brBlue0: '#0038b8',
  brBlue1: '#1125a9',
  brBlue2: '#0d1f8f',
  brBlue3: '#10206c',
  textOnLight: '#0a0a0a',

  // new colors
  whiteForBlue: '#f9f6f4',
  Blue: '#0000ff',
  BlueLight: '#2f14ff',
  redForBlue: '#fa6166',
  BlueDark: '#0d1458',


};
