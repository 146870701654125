import React from "react"

const LBLogo = (props) => (
  <svg
    width={116}
    height={45}
    {...props}
    aria-labelledby="locksbridge-artist-management-homepage"
    fill={props.fill}
  >
    <path
      className="cls-1"
      d="M99.27,30.59c-.59,0-1.06.03-1.42.1s-.67.17-.93.32c.66.63.99,1.41.99,2.34,0,.7-.19,1.32-.59,1.86-.38.54-.91.96-1.58,1.25-.68.29-1.44.44-2.3.44-1.08,0-2.01-.22-2.8-.67-.22.25-.33.51-.33.78,0,.34.13.59.38.74.26.15.6.25,1,.31.41.05.98.07,1.69.08,1.1.03,2.02.12,2.77.26.75.15,1.35.43,1.8.85.46.42.7,1,.7,1.75s-.27,1.45-.81,2.06c-.54.61-1.26,1.09-2.16,1.43-.91.35-1.9.52-2.98.52-1.37,0-2.4-.25-3.1-.76-.7-.5-1.05-1.2-1.05-2.08s.38-1.62,1.13-2.25c-.51-.42-.77-.98-.77-1.66,0-.87.46-1.63,1.39-2.27-.43-.31-.77-.68-1-1.13-.24-.46-.35-.95-.35-1.49,0-.7.19-1.33.59-1.86.39-.54.92-.96,1.6-1.25.68-.31,1.45-.45,2.3-.45,1.32,0,2.4.33,3.22.96.49-1.35,1.37-2.04,2.62-2.04v1.87h-.02s0,0,0,0ZM93.42,36.42c.57,0,1-.26,1.31-.79.31-.53.46-1.3.46-2.27s-.15-1.73-.46-2.27c-.31-.54-.74-.81-1.31-.81s-.98.27-1.3.81-.47,1.3-.47,2.27.16,1.73.47,2.27c.31.53.75.81,1.3.81h0ZM90.04,40.19c-.11.21-.17.48-.17.81,0,.53.2,1.04.61,1.51.42.47.98.84,1.66,1.13.7.29,1.45.43,2.27.43.87,0,1.59-.17,2.15-.49.57-.33.85-.77.85-1.3,0-.35-.1-.63-.31-.82-.21-.19-.55-.34-1.02-.43-.48-.08-1.17-.15-2.06-.18-.94-.03-1.71-.08-2.27-.14-.57-.07-1.05-.18-1.41-.36l-.29-.14h0s0-.01,0-.01Z"
    ></path>
    <path
      className="cls-1"
      d="M.02,40.47c.23-.25.45-.56.64-.93.19-.38.33-.77.42-1.17l1.99-10.31c.05-.25.07-.49.07-.72,0-.53-.1-1-.29-1.39l.03-.11h3.27l-.03.11c-.24.25-.45.57-.64.94-.19.38-.33.77-.42,1.16l-2.33,12.09h2.7c1.19,0,2.16-.24,2.88-.73.74-.49,1.45-1.27,2.15-2.36h.2l-1.21,3.52H0l.03-.11H.03s0,0,0,0H.02Z"
    ></path>
    <path
      className="cls-1"
      d="M15.26,40.82c-1.27,0-2.27-.4-3-1.2-.73-.8-1.09-1.86-1.09-3.18,0-1.22.25-2.34.74-3.35.49-1.02,1.16-1.8,1.99-2.39.84-.59,1.73-.88,2.68-.88,1.26,0,2.26.4,3,1.2s1.1,1.86,1.1,3.18c0,1.23-.25,2.34-.74,3.35-.49,1-1.16,1.8-1.99,2.39-.84.59-1.73.87-2.68.87h0,0ZM15.29,40.38c.63,0,1.18-.31,1.69-.92.5-.63.91-1.44,1.2-2.44.31-1.02.45-2.08.45-3.22s-.18-2.08-.55-2.65-.88-.87-1.54-.87c-.61,0-1.17.31-1.69.93-.5.61-.91,1.43-1.2,2.44-.29,1-.44,2.08-.44,3.21,0,1.2.18,2.08.55,2.66.38.57.89.85,1.52.85v.02s0,0,0,0Z"
    ></path>
    <path
      className="cls-1"
      d="M25.85,40.82c-.79,0-1.48-.18-2.08-.54-.59-.36-1.06-.89-1.4-1.55s-.5-1.44-.5-2.3c0-1.21.25-2.32.74-3.31.49-1,1.16-1.8,2.01-2.39s1.78-.89,2.79-.89c1.19,0,2.19.29,3,.85l-.5,2.76h-.21c-.07-.98-.31-1.73-.74-2.25-.43-.53-1.04-.79-1.81-.79-.59,0-1.14.22-1.65.66-.51.44-.92,1.06-1.24,1.87s-.47,1.74-.47,2.79c0,1.2.23,2.14.7,2.82.46.68,1.15,1.02,2.07,1.02.56,0,1.09-.15,1.6-.45.51-.31.96-.77,1.34-1.4h.23c-.23.83-.68,1.56-1.37,2.18-.68.63-1.5.93-2.48.93h-.03s0,0,0,0Z"
    ></path>
    <path
      className="cls-1"
      d="M37.69,40.82c-.38,0-.71-.1-1-.32-.29-.22-.54-.59-.77-1.11l-1.46-3.33v-.1c1.21-.66,2.16-1.33,2.82-2.01s.99-1.33.99-1.97c0-.52-.26-.78-.78-.78-.78,0-1.55.59-2.29,1.74-.74,1.16-1.3,2.47-1.69,3.93l-.64,3.73h-1.81l2.13-12.12c.1-.63.14-1.04.14-1.24,0-.31-.05-.53-.14-.7-.08-.18-.23-.35-.43-.49l.03-.11,2.74-1.02h.11l-1.58,8.98h.14c.54-1.4,1.19-2.43,1.95-3.08.76-.66,1.54-.98,2.34-.98.48,0,.85.13,1.13.38.29.25.43.59.43,1.02,0,.91-.33,1.69-1.02,2.36-.68.67-1.59,1.3-2.74,1.89l1.56,3.47c.15.33.27.54.35.64.08.11.18.17.27.17.34,0,.77-.67,1.28-2.01h.35c-.36,1.2-.74,2.01-1.11,2.43-.38.41-.81.61-1.28.61h-.02s0,.01,0,.01Z"
    ></path>
    <path
      className="cls-1"
      d="M44.17,40.82c-.72,0-1.4-.11-2.02-.35-.63-.23-1.18-.57-1.66-1l.55-2.87h.21c0,.68.14,1.32.4,1.88.27.56.63,1,1.09,1.32.46.33.96.49,1.51.49s1.02-.16,1.37-.49c.35-.32.52-.73.52-1.22,0-.38-.14-.75-.4-1.11-.26-.36-.74-.84-1.41-1.43-.89-.77-1.52-1.41-1.9-1.88-.38-.48-.56-1.01-.56-1.6,0-.83.32-1.49.96-1.99s1.42-.75,2.36-.75c.61,0,1.19.1,1.73.29.54.19.99.42,1.34.7l-.49,2.47h-.21c-.03-.92-.27-1.65-.74-2.16-.46-.52-1.05-.78-1.77-.78-.53,0-.96.14-1.3.42-.34.28-.5.66-.5,1.14,0,.24.05.45.15.63.1.18.29.42.57.68.29.26.75.66,1.4,1.19.77.63,1.33,1.19,1.69,1.67.35.48.53,1.05.53,1.72,0,.96-.31,1.71-.92,2.25-.61.53-1.43.79-2.47.79h-.01Z"
    ></path>
    <path
      className="cls-1"
      d="M49.67,40.47c.49-.66.74-1.54.74-2.65v-9.21c0-1.1-.25-1.99-.74-2.65v-.11h6.24c1.48,0,2.72.28,3.7.85.98.56,1.48,1.44,1.48,2.65,0,.92-.33,1.67-.96,2.23-.64.56-1.41.91-2.3,1.05v.08c1.06.13,1.97.52,2.72,1.19.75.67,1.13,1.55,1.13,2.65,0,1.41-.56,2.43-1.66,3.07-1.11.64-2.47.96-4.09.96h-6.24v-.11h-.01ZM54.99,32.44c.92,0,1.65-.28,2.16-.84.52-.57.78-1.3.78-2.21s-.26-1.61-.79-2.18c-.52-.57-1.23-.85-2.13-.85-.52,0-1.04.1-1.55.29v5.79h1.52s0,0,0,0ZM55.17,40.08c.99,0,1.79-.32,2.39-.96.61-.64.91-1.49.91-2.57s-.31-2.01-.92-2.64c-.61-.63-1.45-.94-2.48-.94h-1.6v6.74c.46.25,1.02.38,1.7.38Z"
    ></path>
    <path
      className="cls-1"
      d="M63.16,40.47c.18-.26.31-.6.42-1.02.11-.42.17-.87.17-1.37v-4.72c0-.57-.07-1.04-.18-1.4-.13-.36-.32-.66-.57-.91v-.11l3.5-1.17h.11v2.88h.1c.24-.92.66-1.63,1.23-2.13.59-.49,1.41-.75,2.45-.75v2.62h-1.22c-.89,0-1.54.18-1.95.55-.42.36-.61.99-.61,1.86v3.28c0,.49.05.94.15,1.37.1.42.25.75.43,1.02v.11h-4.02v-.11h0s0-.01,0-.01Z"
    ></path>
    <path
      className="cls-1"
      d="M71.19,40.47c.18-.26.31-.6.42-1.02.11-.42.17-.87.17-1.37v-4.72c0-.57-.07-1.04-.18-1.4-.13-.36-.32-.66-.57-.91v-.11l3.5-1.17h.11v8.31c0,.49.05.94.15,1.37.1.42.25.75.43,1.02v.11h-4.02v-.11h0ZM73.17,28.59c-.48,0-.88-.16-1.2-.49-.33-.32-.49-.72-.49-1.2s.16-.88.49-1.2c.32-.32.72-.49,1.2-.49s.89.16,1.21.49c.32.33.49.73.49,1.2s-.16.88-.49,1.2c-.33.33-.72.49-1.21.49Z"
    ></path>
    <path
      className="cls-1"
      d="M80.69,40.86c-.84,0-1.58-.21-2.21-.64-.63-.43-1.13-1.04-1.48-1.83-.35-.78-.52-1.71-.52-2.76,0-1.13.22-2.15.68-3.03.46-.89,1.06-1.59,1.83-2.08.77-.49,1.6-.74,2.51-.74,1.04,0,1.87.26,2.48.78v-2.07c0-.59-.07-1.06-.18-1.42s-.31-.66-.55-.89v-.11l3.47-1.17h.1v12.82c0,.57.07,1.04.18,1.4.14.36.31.64.55.87v.11l-3.46.76h-.11v-1.87h-.11c-.34.59-.77,1.05-1.31,1.37-.53.33-1.16.49-1.89.49h.03s0,0,0,0ZM81.97,39.38c.57,0,1.02-.17,1.39-.49.36-.33.57-.75.64-1.28v-5.07c-.07-.57-.27-1.08-.64-1.51-.36-.42-.85-.64-1.44-.64-.76,0-1.35.41-1.78,1.22-.42.81-.64,1.93-.64,3.37s.22,2.45.67,3.24c.46.77,1.06,1.17,1.81,1.17h0Z"
    ></path>
    <path
      className="cls-1"
      d="M109.14,37.04c-.24,1.16-.77,2.08-1.6,2.79-.83.7-1.84,1.05-3.03,1.05-.94,0-1.79-.21-2.55-.61-.76-.42-1.35-1.02-1.8-1.83-.44-.8-.66-1.75-.66-2.85s.24-2.14.72-3.01c.48-.88,1.11-1.56,1.91-2.04.8-.49,1.67-.73,2.6-.73.87,0,1.63.18,2.27.55.64.36,1.13.85,1.48,1.48.34.61.5,1.28.5,2.01h-7.19c.05,1.05.25,1.95.63,2.71.38.75.88,1.32,1.48,1.69.61.38,1.28.56,2.01.56.68,0,1.3-.15,1.83-.46s.92-.74,1.19-1.28h.21v-.02h0ZM103.95,30.4c-.64,0-1.16.25-1.55.75s-.59,1.23-.61,2.18h4.4c-.08-.91-.32-1.61-.7-2.13-.38-.53-.89-.79-1.54-.79h0s0-.01,0-.01Z"
    ></path>
    <path
      className="cls-1"
      d="M111.59,40.79c-.48,0-.88-.15-1.19-.46s-.46-.7-.46-1.16.15-.87.46-1.17c.31-.31.71-.47,1.19-.47s.85.16,1.16.47c.31.31.46.7.46,1.17s-.15.85-.46,1.16-.7.46-1.16.46Z"
    ></path>
    <path
      className="cls-2"
      d="M3.29,8.43c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M4.97,9.02c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M6.65,8.43c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M8.33,9.02c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M10.02,8.43c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M11.69,9.02c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3c0,.17.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M13.38,8.43c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M15.07,9.02c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M16.74,8.43c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M18.43,9.02c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M20.11,8.43c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M21.79,9.02c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M23.47,8.43c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M25.16,9.02c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M26.83,8.43c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M28.52,9.02c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M30.2,8.43c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M31.88,9.02c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M33.56,8.43c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M35.25,9.02c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M36.92,8.43c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M38.61,9.02c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M40.29,8.43c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M41.97,9.02c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M43.65,8.43c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M45.34,9.02c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M47.02,8.43c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M48.7,9.02c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M50.38,8.43c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M52.06,9.02c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M53.75,8.43c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M55.43,9.02c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M57.11,8.43c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M58.79,9.02c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3c0,.17.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M60.47,8.43c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M62.15,9.02c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M63.83,8.43c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M65.52,9.02c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M67.19,8.43c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M68.88,9.02c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M70.56,8.43c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M72.24,9.02c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M73.92,8.43c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M75.61,9.02c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M77.29,8.43c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M78.97,9.02c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M80.66,8.43c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M82.33,9.02c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-1"
      d="M84.02,8.29c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-2"
      d="M89.06,9.02c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M87.38,9.02c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M85.7,9.02c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M90.75,8.43c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M92.42,9.02c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M94.11,8.43c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M95.79,9.02c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M97.47,8.43c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M99.15,9.02c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M100.84,8.43c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M102.52,9.02c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3c0,.17.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M104.2,8.43c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M105.88,9.02c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3c0,.17.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M107.56,8.43c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M109.25,9.02c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M3.29,10.7c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3c0,.17.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M4.97,10.12c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M6.65,10.7c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M8.33,10.12c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M10.02,10.7c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M11.69,10.12c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M13.38,10.7c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M15.07,10.12c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M16.74,10.7c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M18.43,10.12c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M20.11,10.7c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M21.79,10.12c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M23.47,10.7c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M25.16,10.12c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M26.83,10.7c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M28.52,10.12c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M30.2,10.7c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M31.88,10.12c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M33.56,10.7c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M35.25,10.12c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M36.92,10.7c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M38.61,10.12c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M40.29,10.7c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M41.97,10.12c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M43.65,10.7c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M45.34,10.12c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M47.02,10.7c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M48.7,10.12c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M50.38,10.7c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3c0,.17.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M52.06,10.12c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M53.75,10.7c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M55.43,10.12c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M57.11,10.7c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M58.79,10.12c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M60.47,10.7c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M62.15,10.12c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M63.83,10.7c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M65.52,10.12c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M67.19,10.7c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3c0,.17.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M68.88,10.12c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M70.56,10.7c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M72.24,10.12c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M73.92,10.7c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M75.61,10.12c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M77.29,10.7c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M78.97,10.12c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M80.66,10.7c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M82.33,10.12c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-1"
      d="M84.02,10.85c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M85.7,9.97c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.25-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-2"
      d="M89.06,10.12c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M90.75,10.7c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M92.42,10.12c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M94.11,10.7c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M95.79,10.12c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M97.47,10.7c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3c0,.17.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M99.15,10.12c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M100.84,10.7c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M102.51,10.12c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M104.2,10.7c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M105.88,10.12c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M107.57,10.7c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M109.25,10.12c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M3.29,11.79c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M4.97,12.39c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M6.65,11.79c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3c0-.17-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M8.33,12.39c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M10.02,11.79c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M11.69,12.39c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3c0,.17.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M13.38,11.79c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M15.07,12.39c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M16.74,11.79c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M18.43,12.39c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M20.11,11.79c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M21.79,12.39c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M23.47,11.79c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M25.16,12.39c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M26.83,11.79c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M28.52,12.39c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M30.2,11.79c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M31.88,12.39c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M33.56,11.79c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M35.25,12.39c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M36.92,11.79c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M38.61,12.39c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M40.29,11.79c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M41.97,12.39c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-1"
      d="M43.65,11.65c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-2"
      d="M45.34,12.39c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M47.02,11.79c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M48.7,12.39c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M50.38,11.79c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M52.06,12.39c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M53.75,11.79c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3c0-.17-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M55.43,12.39c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M57.11,11.79c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M58.79,12.39c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3c0,.17.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M60.47,11.79c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M62.15,12.39c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M63.83,11.79c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M65.52,12.39c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M67.19,11.79c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M68.88,12.39c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M70.56,11.79c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M72.24,12.39c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M73.92,11.79c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M75.61,12.39c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M77.29,11.79c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M78.97,12.39c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M80.66,11.79c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M82.33,12.39c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-1"
      d="M84.02,11.65c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M85.7,12.53c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-2"
      d="M90.75,11.79c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M92.42,12.39c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M94.11,11.79c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M95.79,12.39c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M97.47,11.79c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M99.15,12.39c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M100.84,11.79c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3c0-.17-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M102.52,12.39c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3c0,.17.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M104.2,11.79c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M105.88,12.39c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3c0,.17.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M107.56,11.79c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M109.25,12.39c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M3.29,14.06c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3c0,.17.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M4.97,13.48c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M6.65,14.06c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M8.33,13.48c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M10.02,14.06c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M11.69,13.48c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M13.38,14.06c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M15.07,13.48c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M16.74,14.06c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M18.43,13.48c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M20.11,14.06c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M21.79,13.48c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M23.47,14.06c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M25.16,13.48c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M26.83,14.06c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M28.52,13.48c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M30.2,14.06c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M31.88,13.48c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M33.56,14.06c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M35.25,13.48c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M36.92,14.06c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M38.61,13.48c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M40.29,14.06c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M41.97,13.48c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-1"
      d="M43.65,14.21c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M45.34,13.33c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-2"
      d="M47.02,14.06c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M48.7,13.48c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M50.38,14.06c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3c0,.17.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M52.06,13.48c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M53.75,14.06c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M55.43,13.48c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M57.11,14.06c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M58.79,13.48c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M60.47,14.06c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M62.15,13.48c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3c0-.17-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M63.83,14.06c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M65.52,13.48c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M67.19,14.06c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3c0,.17.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M68.88,13.48c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M70.56,14.06c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M72.24,13.48c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M73.92,14.06c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M75.61,13.48c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M77.29,14.06c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M78.97,13.48c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M80.66,13.48c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M82.33,13.48c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-1"
      d="M84.02,14.21c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M85.7,13.33c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M87.38,13.33c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-2"
      d="M90.75,14.06c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M89.06,11.79c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M87.38,11.79c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M87.38,10.12c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M89.06,14.06c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M92.42,13.48c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M94.11,14.06c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M95.79,13.48c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M97.47,14.06c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3c0,.17.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M99.15,13.48c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M100.84,14.06c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M102.51,13.48c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M104.2,14.06c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M105.88,13.48c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M107.57,14.06c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M109.25,13.48c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3c0-.17-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M3.29,15.16c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M4.97,15.75c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M6.65,15.16c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3c0-.17-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M8.33,15.75c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M10.02,15.16c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M11.69,15.75c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3c0,.17.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M13.38,15.16c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M15.07,15.75c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M16.74,15.16c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M18.43,15.75c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M20.11,15.16c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M21.79,15.75c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M23.47,15.16c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M25.16,15.75c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-1"
      d="M28.52,15.89c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M26.83,15.89c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-2"
      d="M30.2,15.16c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M31.88,15.75c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M33.56,15.16c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M35.25,15.75c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M36.92,15.16c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M38.61,15.75c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M40.29,15.16c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M41.97,15.75c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-1"
      d="M43.65,15.01c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.25-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M45.34,15.89c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-2"
      d="M48.7,15.75c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M47.02,15.75c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M50.38,15.16c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M52.06,15.75c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M53.75,15.16c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3c0-.17-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M55.43,15.75c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M57.11,15.16c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M58.79,15.75c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3c0,.17.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M60.47,15.16c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M62.15,15.75c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M63.83,15.16c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M65.52,15.75c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M67.19,15.16c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M68.88,15.75c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M70.56,15.16c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M72.24,15.75c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M73.92,15.16c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M75.61,15.75c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M77.29,15.16c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M78.97,15.16c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M80.66,15.16c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M77.29,16.84c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M78.97,16.84c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M80.66,16.84c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M82.33,15.16c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M82.33,16.84c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-1"
      d="M84.02,15.01c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M85.7,15.89c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M87.38,15.89c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-2"
      d="M92.42,15.75c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M94.11,15.16c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M95.79,15.75c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M97.47,15.16c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M99.15,15.75c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M100.84,15.16c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3c0-.17-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M102.52,15.75c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3c0,.17.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M104.2,15.16c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M105.88,15.75c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3c0,.17.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M107.56,15.16c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M109.25,15.75c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M3.29,17.43c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3c0,.17.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M4.97,16.84c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M6.65,17.43c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M8.33,16.84c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M10.02,17.43c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M11.69,16.84c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M13.38,17.43c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M15.07,16.84c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M16.74,17.43c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M18.43,16.84c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M20.11,17.43c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M21.79,16.84c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M23.47,16.84c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-1"
      d="M25.16,16.69c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M26.83,17.57c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.25.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M28.52,16.69c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M30.2,17.57c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-2"
      d="M33.56,17.43c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M31.88,17.43c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M35.25,16.84c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M36.92,17.43c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M38.61,16.84c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M40.29,17.43c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M41.97,16.84c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-1"
      d="M43.65,17.57c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M45.34,16.69c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M47.02,17.57c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-2"
      d="M48.7,16.84c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M50.38,17.43c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3c0,.17.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M52.06,16.84c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M53.75,17.43c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M55.43,16.84c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M57.11,17.43c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M58.79,16.84c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M60.47,17.43c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M62.15,16.84c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3c0-.17-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M63.83,17.43c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M65.52,16.84c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M67.19,17.43c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3c0,.17.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M68.88,16.84c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M70.56,17.43c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M72.24,16.84c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M73.92,17.43c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M75.61,16.84c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-1"
      d="M84.02,17.57c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M85.7,16.69c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M87.38,16.69c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M89.06,16.69c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-2"
      d="M92.42,16.84c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M90.75,15.75c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M89.06,15.75c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M90.75,16.84c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M94.11,17.43c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M95.79,16.84c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M97.47,17.43c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3c0,.17.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M99.15,16.84c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M100.84,17.43c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M102.51,16.84c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M104.2,17.43c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M105.88,16.84c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M107.57,17.43c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M109.25,16.84c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3c0-.17-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M3.29,18.52c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M4.97,19.11c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M6.65,18.52c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3c0-.17-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M8.33,19.11c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M10.02,18.52c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M11.69,19.11c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M13.38,18.52c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-1"
      d="M15.07,19.25c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M16.74,18.38c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M18.43,19.25c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M20.11,18.38c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M21.79,19.25c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M23.47,18.38c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M25.16,19.25c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M26.83,18.38c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M28.52,19.25c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M30.2,18.38c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M31.88,19.25c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M33.56,18.38c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M35.25,19.25c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M36.92,18.38c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M38.61,19.25c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-2"
      d="M40.29,18.52c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M41.97,19.11c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-1"
      d="M43.65,18.38c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M45.34,19.25c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M47.02,18.38c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-2"
      d="M48.7,19.11c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M50.38,18.52c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M52.06,19.11c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M53.75,18.52c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3c0-.17-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M55.43,19.11c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M57.11,18.52c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M58.79,19.11c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M60.47,18.52c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M62.15,19.11c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M63.83,18.52c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M65.52,19.11c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M67.19,18.52c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M68.88,19.11c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M70.56,18.52c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M72.24,19.11c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M73.92,18.52c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-1"
      d="M75.61,19.25c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M77.29,18.38c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M78.97,19.25c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M80.66,18.38c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M82.33,19.25c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M84.02,18.38c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M85.7,19.25c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M87.38,18.38c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M89.06,19.25c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M90.75,19.25c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-2"
      d="M92.42,19.11c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M94.11,18.52c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M95.79,19.11c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M97.47,18.52c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M99.15,19.11c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M100.84,18.52c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3c0-.17-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M102.52,19.11c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M104.2,18.52c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M105.88,19.11c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M107.56,18.52c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M109.25,19.11c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-1"
      d="M3.29,20.94c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M4.97,20.05c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M6.65,20.94c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M8.33,20.05c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M10.02,20.94c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M11.69,20.05c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M13.38,20.94c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M15.07,20.05c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M16.74,20.94c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M18.43,20.05c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M20.11,20.94c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M21.79,20.05c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M23.47,20.94c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M25.16,20.05c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M26.83,20.94c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M28.52,20.05c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M30.2,20.94c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M31.88,20.05c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M33.56,20.94c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M35.25,20.05c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M36.92,20.94c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M38.61,20.05c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M40.29,20.94c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M41.97,20.05c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M43.65,20.94c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M45.34,20.05c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M47.02,20.94c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M48.7,20.05c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M50.38,20.94c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M52.06,20.05c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M53.75,20.94c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M55.43,20.05c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M57.11,20.94c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M58.79,20.05c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M60.47,20.94c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M62.15,20.05c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M63.83,20.94c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M65.52,20.05c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M67.19,20.94c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M68.88,20.05c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M70.56,20.94c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M72.24,20.05c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M73.92,20.94c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M75.61,20.05c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M77.29,20.94c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M78.97,20.05c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M80.66,20.94c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M82.33,20.05c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M84.02,20.94c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M3.29,22.63c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M4.97,21.74c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M6.65,22.63c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M8.33,21.74c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M10.02,22.63c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M11.69,21.74c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M13.38,22.63c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M15.07,21.74c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M16.74,22.63c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M18.43,21.74c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M20.11,22.63c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M21.79,21.74c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M23.47,22.63c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M25.16,21.74c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M26.83,22.63c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M28.52,21.74c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M30.2,22.63c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M31.88,21.74c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M33.56,22.63c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M35.25,21.74c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M36.92,22.63c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M38.61,21.74c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M40.29,22.63c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M41.97,21.74c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M43.65,22.63c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M45.34,21.74c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M47.02,22.63c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M48.7,21.74c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M50.38,22.63c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M52.06,21.74c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M53.75,22.63c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M55.43,21.74c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M57.11,22.63c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M58.79,21.74c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M60.47,22.63c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M62.15,21.74c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M63.83,22.63c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M65.52,21.74c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M67.19,22.63c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M68.88,21.74c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M70.56,22.63c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M72.24,21.74c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M73.92,22.63c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M75.61,21.74c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M77.29,22.63c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M78.97,21.74c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M80.66,22.63c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M82.33,21.74c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M84.02,22.63c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M85.7,20.05c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M87.38,20.94c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M89.06,20.05c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M90.75,20.94c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M92.42,20.05c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M94.11,20.94c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M95.79,20.05c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M97.47,20.94c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M85.7,21.74c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M87.38,22.63c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M89.06,21.74c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M90.75,22.63c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M92.42,21.74c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M94.11,22.63c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M95.79,21.74c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M97.47,22.63c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M99.15,20.94c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M100.84,20.05c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M102.52,20.94c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M104.2,20.05c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M105.88,20.94c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M107.56,20.05c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M109.25,20.94c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M99.15,22.63c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M100.84,21.74c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M102.52,22.63c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M104.2,21.74c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M105.88,22.63c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44.2.44.44.44Z"
    ></path>
    <path
      className="cls-1"
      d="M107.56,21.74c-.24,0-.44.2-.44.44s.2.44.44.44.44-.2.44-.44c0-.24-.2-.44-.44-.44Z"
    ></path>
    <path
      className="cls-1"
      d="M109.25,22.63c.24,0,.44-.2.44-.44s-.2-.44-.44-.44-.44.2-.44.44c0,.24.2.44.44.44Z"
    ></path>
    <path
      className="cls-2"
      d="M3.29,7.34c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M4.97,6.75c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M6.65,7.34c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M8.33,6.75c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M10.02,7.34c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M11.69,6.75c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M13.38,7.34c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M15.07,6.75c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M16.74,7.34c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M18.43,6.75c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M20.11,7.34c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M21.79,6.75c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M23.47,7.34c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M25.16,6.75c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M26.83,7.34c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M28.52,6.75c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M30.2,7.34c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M31.88,6.75c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M33.56,7.34c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M35.25,6.75c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M36.92,7.34c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M38.61,6.75c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M40.29,7.34c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M41.97,6.75c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M43.65,7.34c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M45.34,6.75c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M47.02,7.34c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M48.7,6.75c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M50.38,7.34c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M52.06,6.75c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M53.75,7.34c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M55.43,6.75c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M57.11,7.34c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M58.79,6.75c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M60.47,7.34c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M62.15,6.75c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M63.83,7.34c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M65.52,6.75c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M67.19,7.34c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M68.88,6.75c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M70.56,7.34c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M72.24,6.75c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M73.92,7.34c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M75.61,6.75c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M77.29,7.34c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M78.97,6.75c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M80.66,7.34c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M82.33,6.75c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M85.7,6.75c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M84.02,6.75c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M87.38,7.34c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M89.06,6.75c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M90.75,7.34c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M92.42,6.75c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M94.11,7.34c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M95.79,6.75c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M97.47,7.34c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M99.15,6.75c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M100.84,7.34c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M102.51,6.75c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M104.2,7.34c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M105.88,6.75c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M107.57,7.34c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M109.25,6.75c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M3.29,5.07c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M4.97,5.66c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M6.65,5.07c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3c0-.17-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M8.33,5.66c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M10.02,5.07c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M11.69,5.66c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M13.38,5.07c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M15.07,5.66c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M16.74,5.07c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M18.43,5.66c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M20.11,5.07c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M21.79,5.66c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M23.47,5.07c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M25.16,5.66c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M26.83,5.07c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M28.52,5.66c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M30.2,5.07c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M31.88,5.66c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M33.56,5.07c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M35.25,5.66c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M36.92,5.07c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M38.61,5.66c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M40.29,5.07c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M41.97,5.66c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M43.65,5.07c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M45.34,5.66c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M47.02,5.07c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M48.7,5.66c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M50.38,5.07c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M52.06,5.66c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M53.75,5.07c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3c0-.17-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M55.43,5.66c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M57.11,5.07c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M58.79,5.66c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M60.47,5.07c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M62.15,5.66c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M63.83,5.07c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M65.52,5.66c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M67.19,5.07c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M68.88,5.66c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M70.56,5.07c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M72.24,5.66c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M73.92,5.07c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M75.61,5.66c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M77.29,5.07c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M78.97,5.66c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M80.66,5.07c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M82.33,5.66c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M84.02,5.07c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M85.7,5.66c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M87.38,5.07c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M89.06,5.66c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M90.75,5.07c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M92.42,5.66c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M94.11,5.07c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M95.79,5.66c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M97.47,5.07c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M99.15,5.66c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M100.84,5.07c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3c0-.17-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M102.52,5.66c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M104.2,5.07c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M105.88,5.66c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M107.56,5.07c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M109.25,5.66c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M3.29,3.97c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3c0,.17.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M4.97,3.38c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M6.65,3.97c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M8.33,3.38c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M10.02,3.97c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M11.69,3.38c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M13.38,3.97c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M15.07,3.38c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M16.74,3.97c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M18.43,3.38c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M20.11,3.97c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M21.79,3.38c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M23.47,3.97c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M25.16,3.38c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M26.83,3.97c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M28.52,3.38c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M30.2,3.97c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M31.88,3.38c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M33.56,3.97c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M35.25,3.38c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M36.92,3.97c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M38.61,3.38c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M40.29,3.97c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M41.97,3.38c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M43.65,3.97c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M45.34,3.38c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3c0-.17-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M47.02,3.97c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M48.7,3.38c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M50.38,3.97c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3c0,.17.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M52.06,3.38c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M53.75,3.97c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M55.43,3.38c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M57.11,3.97c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M58.79,3.38c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M60.47,3.97c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M62.15,3.38c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3c0-.17-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M63.83,3.97c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M65.52,3.38c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M67.19,3.97c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3c0,.17.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M68.88,3.38c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M70.56,3.97c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M72.24,3.38c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M73.92,3.97c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M75.61,3.38c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M77.29,3.97c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M78.97,3.38c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M80.66,3.97c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M82.33,3.38c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M85.7,3.38c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M84.02,3.38c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M87.38,3.97c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M89.06,3.38c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M90.75,3.97c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M92.42,3.38c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M94.11,3.97c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M95.79,3.38c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M97.47,3.97c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3c0,.17.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M99.15,3.38c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M100.84,3.97c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M102.51,3.38c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M104.2,3.97c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M105.88,3.38c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M107.57,3.97c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M109.25,3.38c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3c0-.17-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M3.29,1.7c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M4.97,2.29c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M6.65,1.7c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3c0-.17-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M8.33,2.29c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M10.02,1.7c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M11.69,2.29c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3c0,.17.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M13.38,1.7c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M15.07,2.29c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M16.74,1.7c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M18.43,2.29c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M20.11,1.7c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M21.79,2.29c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M23.47,1.7c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M25.16,2.29c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M26.83,1.7c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M28.52,2.29c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M30.2,1.7c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M31.88,2.29c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M33.56,1.7c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M35.25,2.29c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M36.92,1.7c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M38.61,2.29c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M40.29,1.7c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M41.97,2.29c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M43.65,1.7c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M45.34,2.29c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M47.02,1.7c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M48.7,2.29c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M50.38,1.7c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M52.06,2.29c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M53.75,1.7c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3c0-.17-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M55.43,2.29c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M57.11,1.7c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M58.79,2.29c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3c0,.17.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M60.47,1.7c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M62.15,2.29c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M63.83,1.7c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M65.52,2.29c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M67.19,1.7c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M68.88,2.29c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M70.56,1.7c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M72.24,2.29c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M73.92,1.7c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M75.61,2.29c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M77.29,1.7c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M78.97,2.29c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M80.66,1.7c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M82.33,2.29c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M84.02,1.7c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M85.7,2.29c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M87.38,1.7c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M89.06,2.29c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M90.75,1.7c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M92.42,2.29c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M94.11,1.7c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M95.79,2.29c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M97.47,1.7c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M99.15,2.29c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M100.84,1.7c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3c0-.17-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M102.52,2.29c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3c0,.17.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M104.2,1.7c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M105.88,2.29c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3c0,.17.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M107.56,1.7c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M109.25,2.29c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M3.29.6c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M4.97,0c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3S5.15,0,4.97,0Z"
    ></path>
    <path
      className="cls-2"
      d="M6.65.6c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M8.33,0c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3S8.51,0,8.33,0Z"
    ></path>
    <path
      className="cls-2"
      d="M10.02.6c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M11.69,0c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3S11.87,0,11.69,0Z"
    ></path>
    <path
      className="cls-2"
      d="M13.38.6c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M15.07,0c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3S15.23,0,15.07,0Z"
    ></path>
    <path
      className="cls-2"
      d="M16.74.6c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M18.43,0c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M20.11.6c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M21.79,0c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M23.47.6c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M25.16,0c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M26.83.6c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M28.52,0c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M30.2.6c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M31.88,0c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M33.56.6c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M35.25,0c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M36.92.6c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M38.61,0c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M40.29.6c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M41.97,0c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M43.65.6c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M45.34,0c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M47.02.6c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M48.7,0c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M50.38.6c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M52.06,0c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M53.75.6c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M55.43,0c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M57.11.6c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M58.79,0c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M60.47.6c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M62.15,0c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M63.83.6c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M65.52,0c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M67.19.6c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M68.88,0c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M70.56.6c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M72.24,0c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M73.92.6c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M75.61,0c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M77.29.6c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M78.97,0c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M80.66.6c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M82.33,0c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M85.7,0c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M84.02,0c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M87.38.6c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M89.06,0c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M90.75.6c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M92.42,0c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M94.11.6c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M95.79,0c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M97.47.6c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M99.15,0c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M100.84.6c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M102.51,0c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M104.2.6c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M105.88,0c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
    <path
      className="cls-2"
      d="M107.57.6c.16,0,.3-.13.3-.3s-.13-.3-.3-.3-.3.13-.3.3.13.3.3.3Z"
    ></path>
    <path
      className="cls-2"
      d="M109.25,0c-.16,0-.3.13-.3.3s.13.3.3.3.3-.13.3-.3-.13-.3-.3-.3Z"
    ></path>
  </svg>
)

export default LBLogo
