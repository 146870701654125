import React from "react"

const LocksBridge = (props) => (
  <svg
    width={154}
    height={60}
    {...props}
    aria-labelledby="locksbridge-artist-management-homepage"
  >
      <path className="cls-1"
            d="M132.37,40.79c-.79,0-1.42.04-1.89.13s-.89.23-1.24.43c.89.84,1.32,1.88,1.32,3.12,0,.93-.25,1.75-.78,2.48-.51.72-1.22,1.28-2.11,1.67-.9.39-1.92.58-3.06.58-1.44,0-2.68-.3-3.73-.89-.3.33-.43.68-.43,1.04,0,.45.17.78.51.98.35.2.8.34,1.33.42.55.06,1.3.1,2.25.11,1.47.04,2.69.16,3.69.35,1,.2,1.8.57,2.4,1.13.62.56.93,1.33.93,2.34s-.36,1.93-1.09,2.74c-.72.81-1.68,1.45-2.88,1.9-1.21.46-2.53.69-3.97.69-1.82,0-3.19-.34-4.13-1.02-.93-.67-1.4-1.6-1.4-2.77s.5-2.16,1.51-3c-.68-.56-1.02-1.31-1.02-2.21,0-1.16.62-2.17,1.85-3.03-.57-.42-1.02-.91-1.33-1.51-.32-.61-.47-1.27-.47-1.99,0-.94.25-1.77.78-2.48.52-.72,1.22-1.28,2.13-1.67.9-.41,1.94-.6,3.07-.6,1.75,0,3.19.43,4.3,1.28.65-1.81,1.82-2.72,3.5-2.72v2.49h-.03s0,0,0,0ZM124.56,48.56c.76,0,1.33-.35,1.74-1.05.42-.71.62-1.73.62-3.03s-.2-2.3-.62-3.02c-.42-.72-.99-1.09-1.74-1.09s-1.31.36-1.74,1.09-.63,1.74-.63,3.02.21,2.3.63,3.02c.42.71,1,1.08,1.74,1.08v-.02ZM120.05,53.59c-.15.28-.23.64-.23,1.09,0,.71.27,1.38.82,2.01.56.63,1.3,1.12,2.21,1.5.93.38,1.94.57,3.02.57,1.16,0,2.12-.23,2.86-.66.76-.43,1.13-1.02,1.13-1.74,0-.47-.14-.84-.42-1.09-.28-.25-.74-.45-1.36-.57-.64-.11-1.55-.2-2.74-.23-1.26-.04-2.27-.1-3.03-.19-.76-.09-1.4-.23-1.88-.48l-.39-.19v-.02h0Z"></path>
      <path className="cls-1" d="M.03,53.97c.31-.33.6-.75.85-1.24.25-.51.43-1.02.56-1.55l2.65-13.75c.06-.33.09-.65.09-.95,0-.71-.13-1.33-.39-1.85l.03-.15h4.36l-.03.15c-.32.33-.6.76-.85,1.26-.25.51-.43,1.02-.56,1.55l-3.11,16.12h3.59c1.59,0,2.87-.32,3.85-.97.98-.65,1.93-1.69,2.86-3.14h.27l-1.61,4.7H0l.03-.15H.03s0,0,0,0H.03Z"></path>
      <path className="cls-1"
            d="M20.35,54.43c-1.69,0-3.02-.53-4-1.6-.97-1.07-1.45-2.48-1.45-4.24,0-1.63.33-3.12.98-4.46.65-1.35,1.55-2.4,2.65-3.19,1.12-.78,2.31-1.17,3.58-1.17,1.68,0,3.01.53,3.99,1.6s1.47,2.48,1.47,4.24c0,1.64-.33,3.12-.98,4.47-.66,1.34-1.55,2.4-2.65,3.19-1.12.78-2.31,1.16-3.58,1.16h0,0ZM20.39,53.84c.83,0,1.57-.42,2.25-1.23.67-.83,1.21-1.92,1.6-3.26.41-1.35.6-2.78.6-4.3s-.24-2.77-.74-3.53-1.17-1.16-2.06-1.16c-.82,0-1.56.42-2.25,1.24-.67.82-1.22,1.91-1.6,3.26-.39,1.34-.58,2.77-.58,4.28,0,1.6.24,2.78.74,3.54.5.76,1.18,1.14,2.03,1.14v.03s0,0,0,0Z"></path>
      <path className="cls-1"
            d="M34.47,54.43c-1.05,0-1.98-.24-2.77-.72-.79-.49-1.42-1.18-1.87-2.07s-.67-1.92-.67-3.07c0-1.61.33-3.09.98-4.42.65-1.34,1.55-2.4,2.67-3.19s2.37-1.19,3.72-1.19c1.59,0,2.92.38,3.99,1.14l-.67,3.68h-.28c-.09-1.31-.41-2.31-.98-3-.57-.7-1.38-1.05-2.41-1.05-.79,0-1.52.3-2.21.89-.68.58-1.23,1.42-1.66,2.49s-.63,2.32-.63,3.72c0,1.61.31,2.86.93,3.76.62.9,1.54,1.35,2.76,1.35.75,0,1.46-.2,2.14-.6.68-.41,1.28-1.02,1.79-1.87h.31c-.31,1.11-.9,2.07-1.82,2.91-.9.83-2.01,1.24-3.31,1.24h-.03s0,0,0,0Z"></path>
      <path className="cls-1"
            d="M50.26,54.43c-.5,0-.95-.14-1.33-.43-.38-.3-.72-.79-1.02-1.48l-1.94-4.44v-.13c1.61-.89,2.87-1.78,3.76-2.68s1.32-1.78,1.32-2.63c0-.69-.35-1.04-1.04-1.04-1.04,0-2.07.78-3.06,2.32-.98,1.55-1.74,3.29-2.26,5.23l-.86,4.97h-2.41l2.84-16.16c.13-.83.19-1.38.19-1.66,0-.41-.06-.71-.19-.94-.11-.23-.31-.46-.57-.66l.03-.15,3.65-1.35h.15l-2.11,11.97h.18c.72-1.87,1.59-3.24,2.6-4.11,1.01-.88,2.06-1.31,3.12-1.31.64,0,1.14.17,1.51.51.38.33.57.79.57,1.36,0,1.21-.44,2.26-1.35,3.15-.9.89-2.12,1.74-3.65,2.53l2.08,4.63c.2.43.36.72.47.86.11.15.23.23.36.23.45,0,1.02-.89,1.71-2.68h.47c-.48,1.61-.98,2.68-1.48,3.24-.51.55-1.09.82-1.71.82l-.03.02h0Z"></path>
      <path className="cls-1"
            d="M58.89,54.43c-.95,0-1.87-.15-2.69-.46-.84-.31-1.57-.76-2.21-1.33l.74-3.83h.28c0,.91.18,1.75.54,2.51.36.75.84,1.33,1.45,1.75.62.43,1.28.65,2.01.65s1.35-.21,1.82-.65c.46-.43.69-.97.69-1.63,0-.51-.18-1-.54-1.48-.35-.49-.98-1.12-1.88-1.91-1.19-1.03-2.03-1.88-2.53-2.51-.5-.64-.75-1.35-.75-2.13,0-1.11.43-1.99,1.28-2.65s1.89-1,3.14-1c.82,0,1.59.13,2.3.38.72.25,1.32.56,1.79.94l-.65,3.29h-.29c-.04-1.23-.36-2.2-.98-2.88-.62-.69-1.41-1.04-2.36-1.04-.7,0-1.28.19-1.74.56-.45.37-.67.89-.67,1.52,0,.32.06.6.2.84.14.24.39.56.76.9.38.35,1,.89,1.87,1.59,1.02.84,1.78,1.59,2.25,2.22.47.64.7,1.41.7,2.29,0,1.28-.42,2.27-1.23,3-.81.7-1.9,1.05-3.29,1.05h-.02Z"></path>
      <path className="cls-1"
            d="M66.22,53.97c.65-.89.98-2.06.98-3.53v-12.28c0-1.47-.33-2.65-.98-3.53v-.15h8.33c1.98,0,3.63.37,4.94,1.13,1.31.75,1.97,1.92,1.97,3.53,0,1.23-.43,2.22-1.28,2.98-.86.75-1.88,1.22-3.07,1.41v.1c1.42.17,2.63.69,3.62,1.59,1,.89,1.51,2.07,1.51,3.53,0,1.88-.75,3.24-2.21,4.09-1.48.86-3.3,1.28-5.45,1.28h-8.33v-.15h-.02ZM73.32,43.25c1.23,0,2.21-.37,2.88-1.12.69-.76,1.04-1.74,1.04-2.95s-.35-2.15-1.05-2.91c-.69-.76-1.64-1.14-2.84-1.14-.69,0-1.38.13-2.07.39v7.72h2.03s0,0,0,0ZM73.56,53.44c1.32,0,2.39-.43,3.19-1.28.81-.85,1.22-1.99,1.22-3.43s-.42-2.67-1.23-3.52c-.82-.84-1.93-1.26-3.31-1.26h-2.13v8.99c.61.33,1.36.5,2.27.5Z"></path>
      <path className="cls-1" d="M84.21,53.97c.23-.35.42-.8.56-1.35.15-.56.23-1.16.23-1.82v-6.29c0-.76-.09-1.38-.24-1.87-.17-.48-.43-.89-.76-1.22v-.15l4.66-1.56h.15v3.85h.13c.32-1.23.88-2.18,1.64-2.84.78-.66,1.88-1,3.26-1v3.5h-1.63c-1.18,0-2.06.24-2.6.74-.56.49-.82,1.32-.82,2.48v4.38c0,.66.06,1.26.2,1.82.14.56.33,1,.57,1.35v.15h-5.36v-.16s0,0,0,0Z"></path>
      <path className="cls-1" d="M94.91,53.97c.23-.35.42-.8.56-1.35.15-.56.23-1.16.23-1.82v-6.29c0-.76-.09-1.38-.24-1.87-.17-.48-.43-.89-.76-1.22v-.15l4.66-1.56h.15v11.09c0,.66.06,1.26.2,1.82.14.56.33,1,.57,1.35v.15h-5.36v-.15h0ZM97.56,38.11c-.64,0-1.17-.22-1.6-.65-.43-.43-.65-.95-.65-1.6s.22-1.17.65-1.6c.43-.43.95-.65,1.6-.65s1.18.22,1.61.65c.43.43.65.97.65,1.6s-.22,1.17-.65,1.6c-.43.44-.96.65-1.61.65Z"></path>
      <path className="cls-1"
            d="M107.58,54.48c-1.12,0-2.1-.29-2.95-.85-.84-.57-1.5-1.38-1.97-2.44-.46-1.04-.69-2.27-.69-3.68,0-1.51.3-2.86.9-4.05.61-1.19,1.42-2.12,2.44-2.77,1.02-.65,2.14-.98,3.35-.98,1.38,0,2.49.35,3.31,1.04v-2.76c0-.78-.09-1.42-.24-1.89s-.41-.88-.74-1.19v-.15l4.63-1.56h.13v17.09c0,.76.09,1.38.24,1.87.18.48.42.86.74,1.16v.15l-4.62,1.02h-.15v-2.49h-.15c-.45.79-1.03,1.4-1.74,1.83-.7.43-1.55.66-2.53.66h.04s0,0,0,0ZM109.29,52.51c.76,0,1.36-.23,1.85-.66.49-.43.76-1,.85-1.7v-6.76c-.09-.76-.36-1.44-.86-2.01-.49-.56-1.13-.85-1.92-.85-1.02,0-1.8.55-2.37,1.63-.56,1.09-.85,2.58-.85,4.49s.3,3.26.89,4.31c.61,1.03,1.42,1.55,2.41,1.55h0Z"></path>
      <path className="cls-1"
            d="M145.52,49.38c-.32,1.55-1.02,2.78-2.13,3.72-1.11.93-2.46,1.4-4.05,1.4-1.26,0-2.39-.28-3.4-.82-1.01-.56-1.8-1.36-2.4-2.44-.58-1.07-.89-2.34-.89-3.8s.32-2.86.95-4.01c.64-1.17,1.48-2.08,2.55-2.73,1.07-.65,2.22-.97,3.47-.97,1.16,0,2.17.24,3.03.74.86.49,1.51,1.14,1.97,1.97.45.82.67,1.71.67,2.67h-9.59c.06,1.41.34,2.6.84,3.61.51,1,1.17,1.75,1.98,2.26.82.5,1.71.75,2.68.75.91,0,1.74-.2,2.44-.62s1.23-.98,1.59-1.7h.28v-.03h0ZM138.6,40.54c-.85,0-1.55.33-2.07,1s-.79,1.64-.81,2.91h5.87c-.1-1.21-.43-2.15-.94-2.84-.51-.7-1.19-1.05-2.06-1.05v-.02h0Z"></path>
      <path className="cls-1" d="M148.79,54.39c-.64,0-1.17-.2-1.59-.62s-.62-.93-.62-1.55.2-1.16.62-1.56c.42-.42.95-.63,1.59-.63s1.13.22,1.55.63c.42.42.62.94.62,1.56s-.2,1.13-.62,1.55-.93.62-1.55.62Z"></path>
      <path className="cls-2" d="M4.38,11.24c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M6.63,12.02c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M8.87,11.24c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M11.11,12.02c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M13.36,11.24c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M15.59,12.02c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4c0,.23.17.4.4.4Z"></path>
      <path className="cls-2" d="M17.84,11.24c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M20.09,12.02c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M22.32,11.24c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M24.57,12.02c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M26.82,11.24c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M29.05,12.02c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M31.3,11.24c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M33.54,12.02c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M35.78,11.24c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M38.02,12.02c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M40.27,11.24c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M42.5,12.02c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M44.75,11.24c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M47,12.02c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M49.23,11.24c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M51.48,12.02c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M53.73,11.24c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M55.96,12.02c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M58.21,11.24c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M60.45,12.02c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M62.69,11.24c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M64.93,12.02c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M67.18,11.24c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M69.42,12.02c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M71.66,11.24c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M73.9,12.02c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M76.14,11.24c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M78.38,12.02c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4c0,.23.17.4.4.4Z"></path>
      <path className="cls-2" d="M80.63,11.24c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M82.87,12.02c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M85.11,11.24c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M87.36,12.02c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M89.59,11.24c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M91.84,12.02c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M94.09,11.24c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M96.32,12.02c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M98.57,11.24c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M100.81,12.02c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M103.05,11.24c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M105.29,12.02c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M107.54,11.24c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M109.78,12.02c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-1" d="M112.02,11.05c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-2" d="M118.75,12.02c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M116.5,12.02c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M114.27,12.02c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M121,11.24c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M123.23,12.02c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M125.48,11.24c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M127.72,12.02c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M129.96,11.24c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M132.21,12.02c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M134.45,11.24c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M136.69,12.02c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4c0,.23.17.4.4.4Z"></path>
      <path className="cls-2" d="M138.93,11.24c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M141.17,12.02c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4c0,.23.17.4.4.4Z"></path>
      <path className="cls-2" d="M143.41,11.24c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M145.66,12.02c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M4.38,14.27c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4c0,.22.17.4.4.4Z"></path>
      <path className="cls-2" d="M6.63,13.49c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M8.87,14.27c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M11.11,13.49c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M13.36,14.27c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M15.59,13.49c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M17.84,14.27c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M20.09,13.49c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M22.32,14.27c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M24.57,13.49c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M26.82,14.27c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M29.05,13.49c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M31.3,14.27c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M33.54,13.49c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M35.78,14.27c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M38.02,13.49c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M40.27,14.27c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M42.5,13.49c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M44.75,14.27c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M47,13.49c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M49.23,14.27c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M51.48,13.49c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M53.73,14.27c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M55.96,13.49c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M58.21,14.27c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M60.45,13.49c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M62.69,14.27c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M64.93,13.49c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M67.18,14.27c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4c0,.22.17.4.4.4Z"></path>
      <path className="cls-2" d="M69.42,13.49c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M71.66,14.27c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M73.9,13.49c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M76.14,14.27c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M78.38,13.49c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M80.63,14.27c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M82.87,13.49c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M85.11,14.27c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M87.36,13.49c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M89.59,14.27c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4c0,.22.17.4.4.4Z"></path>
      <path className="cls-2" d="M91.84,13.49c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M94.09,14.27c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M96.32,13.49c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M98.57,14.27c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M100.81,13.49c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M103.05,14.27c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M105.29,13.49c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M107.54,14.27c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M109.78,13.49c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-1" d="M112.02,14.46c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M114.27,13.29c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.33-.27-.59-.59-.59Z"></path>
      <path className="cls-2" d="M118.75,13.49c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M121,14.27c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M123.23,13.49c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M125.48,14.27c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M127.72,13.49c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M129.97,14.27c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4c0,.22.17.4.4.4Z"></path>
      <path className="cls-2" d="M132.21,13.49c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M134.45,14.27c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M136.69,13.49c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M138.93,14.27c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M141.17,13.49c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M143.42,14.27c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M145.66,13.49c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M4.38,15.72c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M6.63,16.52c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M8.87,15.72c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4c0-.22-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M11.11,16.52c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M13.36,15.72c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M15.59,16.52c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4c0,.22.17.4.4.4Z"></path>
      <path className="cls-2" d="M17.84,15.72c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M20.09,16.52c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M22.32,15.72c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M24.57,16.52c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M26.82,15.72c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M29.05,16.52c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M31.3,15.72c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M33.54,16.52c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M35.78,15.72c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M38.02,16.52c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M40.27,15.72c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M42.5,16.52c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M44.75,15.72c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M47,16.52c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M49.23,15.72c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M51.48,16.52c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M53.73,15.72c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M55.96,16.52c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-1" d="M58.21,15.53c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-2" d="M60.45,16.52c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M62.69,15.72c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M64.93,16.52c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M67.18,15.72c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M69.42,16.52c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M71.66,15.72c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4c0-.22-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M73.9,16.52c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M76.14,15.72c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M78.38,16.52c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4c0,.22.17.4.4.4Z"></path>
      <path className="cls-2" d="M80.63,15.72c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M82.87,16.52c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M85.11,15.72c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M87.36,16.52c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M89.59,15.72c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M91.84,16.52c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M94.09,15.72c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M96.32,16.52c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M98.57,15.72c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M100.81,16.52c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M103.05,15.72c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M105.29,16.52c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M107.54,15.72c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M109.78,16.52c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-1" d="M112.02,15.53c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M114.27,16.71c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-2" d="M121,15.72c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M123.23,16.52c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M125.48,15.72c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M127.72,16.52c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M129.96,15.72c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M132.21,16.52c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M134.45,15.72c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4c0-.22-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M136.69,16.52c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4c0,.22.17.4.4.4Z"></path>
      <path className="cls-2" d="M138.93,15.72c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M141.17,16.52c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4c0,.22.17.4.4.4Z"></path>
      <path className="cls-2" d="M143.41,15.72c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M145.66,16.52c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M4.38,18.75c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4c0,.23.17.4.4.4Z"></path>
      <path className="cls-2" d="M6.63,17.97c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M8.87,18.75c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M11.11,17.97c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M13.36,18.75c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M15.59,17.97c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M17.84,18.75c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M20.09,17.97c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M22.32,18.75c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M24.57,17.97c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M26.82,18.75c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M29.05,17.97c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M31.3,18.75c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M33.54,17.97c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M35.78,18.75c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M38.02,17.97c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M40.27,18.75c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M42.5,17.97c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M44.75,18.75c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M47,17.97c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M49.23,18.75c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M51.48,17.97c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M53.73,18.75c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M55.96,17.97c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-1" d="M58.21,18.94c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M60.45,17.78c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-2" d="M62.69,18.75c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M64.93,17.97c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M67.18,18.75c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4c0,.23.17.4.4.4Z"></path>
      <path className="cls-2" d="M69.42,17.97c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M71.66,18.75c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M73.9,17.97c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M76.14,18.75c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M78.38,17.97c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M80.63,18.75c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M82.87,17.97c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4c0-.22-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M85.11,18.75c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M87.36,17.97c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M89.59,18.75c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4c0,.23.17.4.4.4Z"></path>
      <path className="cls-2" d="M91.84,17.97c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M94.09,18.75c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M96.32,17.97c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M98.57,18.75c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M100.81,17.97c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M103.05,18.75c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M105.29,17.97c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M107.54,17.97c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M109.78,17.97c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-1" d="M112.02,18.94c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M114.27,17.78c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M116.5,17.78c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-2" d="M121,18.75c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M118.75,15.72c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M116.5,15.72c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M116.5,13.49c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M118.75,18.75c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M123.23,17.97c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M125.48,18.75c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M127.72,17.97c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M129.97,18.75c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4c0,.23.17.4.4.4Z"></path>
      <path className="cls-2" d="M132.21,17.97c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M134.45,18.75c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M136.69,17.97c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M138.93,18.75c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M141.17,17.97c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M143.42,18.75c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M145.66,17.97c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4c0-.22-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M4.38,20.22c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M6.63,21c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M8.87,20.22c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4c0-.23-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M11.11,21c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M13.36,20.22c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M15.59,21c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4c0,.22.17.4.4.4Z"></path>
      <path className="cls-2" d="M17.84,20.22c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M20.09,21c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M22.32,20.22c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M24.57,21c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M26.82,20.22c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M29.05,21c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M31.3,20.22c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M33.54,21c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-1" d="M38.02,21.19c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M35.78,21.19c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-2" d="M40.27,20.22c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M42.5,21c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M44.75,20.22c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M47,21c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M49.23,20.22c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M51.48,21c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M53.73,20.22c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M55.96,21c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-1" d="M58.21,20.02c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.33-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M60.45,21.19c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.26.59.59.59Z"></path>
      <path className="cls-2" d="M64.93,21c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M62.69,21c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M67.18,20.22c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M69.42,21c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M71.66,20.22c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4c0-.23-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M73.9,21c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M76.14,20.22c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M78.38,21c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4c0,.22.17.4.4.4Z"></path>
      <path className="cls-2" d="M80.63,20.22c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M82.87,21c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M85.11,20.22c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M87.36,21c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M89.59,20.22c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M91.84,21c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M94.09,20.22c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M96.32,21c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M98.57,20.22c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M100.81,21c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M103.05,20.22c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M105.29,20.22c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M107.54,20.22c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M103.05,22.45c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M105.29,22.45c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M107.54,22.45c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M109.78,20.22c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M109.78,22.45c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-1" d="M112.02,20.02c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M114.27,21.19c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M116.5,21.19c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-2" d="M123.23,21c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M125.48,20.22c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M127.72,21c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M129.96,20.22c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M132.21,21c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M134.45,20.22c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4c0-.23-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M136.69,21c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4c0,.22.17.4.4.4Z"></path>
      <path className="cls-2" d="M138.93,20.22c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M141.17,21c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4c0,.22.17.4.4.4Z"></path>
      <path className="cls-2" d="M143.41,20.22c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M145.66,21c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M4.38,23.24c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4c0,.22.17.4.4.4Z"></path>
      <path className="cls-2" d="M6.63,22.45c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M8.87,23.24c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M11.11,22.45c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M13.36,23.24c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M15.59,22.45c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M17.84,23.24c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M20.09,22.45c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M22.32,23.24c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M24.57,22.45c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M26.82,23.24c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M29.05,22.45c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M31.3,22.45c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-1" d="M33.54,22.26c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M35.78,23.43c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.33.27.59.59.59Z"></path>
      <path className="cls-1" d="M38.02,22.26c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M40.27,23.43c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-2" d="M44.75,23.24c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M42.5,23.24c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M47,22.45c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M49.23,23.24c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M51.48,22.45c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M53.73,23.24c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M55.96,22.45c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-1" d="M58.21,23.43c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M60.45,22.26c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M62.69,23.43c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-2" d="M64.93,22.45c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M67.18,23.24c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4c0,.22.17.4.4.4Z"></path>
      <path className="cls-2" d="M69.42,22.45c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M71.66,23.24c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M73.9,22.45c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M76.14,23.24c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M78.38,22.45c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M80.63,23.24c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M82.87,22.45c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4c0-.22-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M85.11,23.24c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M87.36,22.45c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M89.59,23.24c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4c0,.22.17.4.4.4Z"></path>
      <path className="cls-2" d="M91.84,22.45c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M94.09,23.24c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M96.32,22.45c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M98.57,23.24c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M100.81,22.45c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-1" d="M112.02,23.43c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M114.27,22.26c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M116.5,22.26c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M118.75,22.26c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-2" d="M123.23,22.45c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M121,21c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M118.75,21c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M121,22.45c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M125.48,23.24c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M127.72,22.45c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M129.97,23.24c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4c0,.22.17.4.4.4Z"></path>
      <path className="cls-2" d="M132.21,22.45c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M134.45,23.24c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M136.69,22.45c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M138.93,23.24c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M141.17,22.45c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M143.42,23.24c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M145.66,22.45c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4c0-.22-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M4.38,24.7c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M6.63,25.48c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M8.87,24.7c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4c0-.23-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M11.11,25.48c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M13.36,24.7c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M15.59,25.48c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M17.84,24.7c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-1" d="M20.09,25.67c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M22.32,24.5c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M24.57,25.67c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M26.82,24.5c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M29.05,25.67c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M31.3,24.5c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M33.54,25.67c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M35.78,24.5c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M38.02,25.67c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M40.27,24.5c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M42.5,25.67c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M44.75,24.5c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M47,25.67c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M49.23,24.5c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M51.48,25.67c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-2" d="M53.73,24.7c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M55.96,25.48c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-1" d="M58.21,24.5c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M60.45,25.67c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.26.59.59.59Z"></path>
      <path className="cls-1" d="M62.69,24.5c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-2" d="M64.93,25.48c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M67.18,24.7c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M69.42,25.48c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M71.66,24.7c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4c0-.23-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M73.9,25.48c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M76.14,24.7c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M78.38,25.48c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M80.63,24.7c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M82.87,25.48c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M85.11,24.7c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M87.36,25.48c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M89.59,24.7c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M91.84,25.48c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M94.09,24.7c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M96.32,25.48c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M98.57,24.7c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-1" d="M100.81,25.67c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M103.05,24.5c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M105.29,25.67c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M107.54,24.5c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M109.78,25.67c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M112.02,24.5c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M114.27,25.67c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M116.5,24.5c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M118.75,25.67c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M121,25.67c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-2" d="M123.23,25.48c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M125.48,24.7c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M127.72,25.48c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M129.96,24.7c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M132.21,25.48c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M134.45,24.7c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4c0-.23-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M136.69,25.48c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M138.93,24.7c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M141.17,25.48c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M143.41,24.7c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M145.66,25.48c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-1" d="M4.38,27.92c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M6.63,26.74c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M8.87,27.92c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.26.59.59.59Z"></path>
      <path className="cls-1" d="M11.11,26.74c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M13.36,27.92c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M15.59,26.74c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.26-.59-.59-.59Z"></path>
      <path className="cls-1" d="M17.84,27.92c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M20.09,26.74c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M22.32,27.92c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M24.57,26.74c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M26.82,27.92c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M29.05,26.74c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M31.3,27.92c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M33.54,26.74c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M35.78,27.92c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M38.02,26.74c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M40.27,27.92c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M42.5,26.74c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M44.75,27.92c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M47,26.74c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M49.23,27.92c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M51.48,26.74c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M53.73,27.92c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.26.59.59.59Z"></path>
      <path className="cls-1" d="M55.96,26.74c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M58.21,27.92c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M60.45,26.74c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M62.69,27.92c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M64.93,26.74c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M67.18,27.92c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M69.42,26.74c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M71.66,27.92c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.26.59.59.59Z"></path>
      <path className="cls-1" d="M73.9,26.74c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M76.14,27.92c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M78.38,26.74c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.26-.59-.59-.59Z"></path>
      <path className="cls-1" d="M80.63,27.92c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M82.87,26.74c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M85.11,27.92c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M87.36,26.74c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M89.59,27.92c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M91.84,26.74c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M94.09,27.92c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M96.32,26.74c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.26-.59-.59-.59Z"></path>
      <path className="cls-1" d="M98.57,27.92c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M100.81,26.74c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M103.05,27.92c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M105.29,26.74c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M107.54,27.92c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M109.78,26.74c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M112.02,27.92c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M4.38,30.17c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M6.63,28.98c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M8.87,30.17c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.26.59.59.59Z"></path>
      <path className="cls-1" d="M11.11,28.98c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M13.36,30.17c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M15.59,28.98c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.26-.59-.59-.59Z"></path>
      <path className="cls-1" d="M17.84,30.17c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M20.09,28.98c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M22.32,30.17c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M24.57,28.98c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M26.82,30.17c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M29.05,28.98c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M31.3,30.17c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M33.54,28.98c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M35.78,30.17c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M38.02,28.98c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M40.27,30.17c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M42.5,28.98c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M44.75,30.17c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M47,28.98c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M49.23,30.17c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M51.48,28.98c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M53.73,30.17c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.26.59.59.59Z"></path>
      <path className="cls-1" d="M55.96,28.98c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M58.21,30.17c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M60.45,28.98c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M62.69,30.17c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M64.93,28.98c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M67.18,30.17c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M69.42,28.98c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M71.66,30.17c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.26.59.59.59Z"></path>
      <path className="cls-1" d="M73.9,28.98c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M76.14,30.17c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M78.38,28.98c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.26-.59-.59-.59Z"></path>
      <path className="cls-1" d="M80.63,30.17c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M82.87,28.98c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M85.11,30.17c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M87.36,28.98c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M89.59,30.17c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M91.84,28.98c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M94.09,30.17c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M96.32,28.98c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.26-.59-.59-.59Z"></path>
      <path className="cls-1" d="M98.57,30.17c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M100.81,28.98c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M103.05,30.17c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M105.29,28.98c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M107.54,30.17c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M109.78,28.98c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M112.02,30.17c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M114.27,26.74c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M116.5,27.92c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M118.75,26.74c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M121,27.92c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M123.23,26.74c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M125.48,27.92c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M127.72,26.74c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M129.97,27.92c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M114.27,28.98c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M116.5,30.17c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M118.75,28.98c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M121,30.17c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M123.23,28.98c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M125.48,30.17c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M127.72,28.98c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M129.97,30.17c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M132.21,27.92c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M134.45,26.74c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M136.69,27.92c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M138.93,26.74c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M141.17,27.92c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M143.41,26.74c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M145.66,27.92c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M132.21,30.17c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-1" d="M134.45,28.98c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M136.69,30.17c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M138.93,28.98c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M141.17,30.17c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59.27.59.59.59Z"></path>
      <path className="cls-1" d="M143.41,28.98c-.32,0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59c0-.32-.27-.59-.59-.59Z"></path>
      <path className="cls-1" d="M145.66,30.17c.32,0,.59-.26.59-.59s-.26-.59-.59-.59-.59.26-.59.59c0,.32.27.59.59.59Z"></path>
      <path className="cls-2" d="M4.38,9.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M6.63,9c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M8.87,9.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M11.11,9c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M13.36,9.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M15.59,9c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M17.84,9.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M20.09,9c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M22.32,9.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M24.57,9c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M26.82,9.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M29.05,9c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M31.3,9.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M33.54,9c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M35.78,9.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M38.02,9c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M40.27,9.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M42.5,9c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M44.75,9.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M47,9c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M49.23,9.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M51.48,9c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M53.73,9.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M55.96,9c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M58.21,9.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M60.45,9c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M62.69,9.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M64.93,9c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M67.18,9.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M69.42,9c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M71.66,9.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M73.9,9c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M76.14,9.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M78.38,9c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M80.63,9.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M82.87,9c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M85.11,9.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M87.36,9c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M89.59,9.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M91.84,9c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M94.09,9.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M96.32,9c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M98.57,9.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M100.81,9c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M103.05,9.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M105.29,9c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M107.54,9.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M109.78,9c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M114.27,9c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M112.02,9c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M116.5,9.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M118.75,9c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M121,9.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M123.23,9c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M125.48,9.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M127.72,9c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M129.97,9.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M132.21,9c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M134.45,9.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M136.69,9c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M138.93,9.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M141.17,9c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M143.42,9.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M145.66,9c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M4.38,6.76c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M6.63,7.54c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M8.87,6.76c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4c0-.22-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M11.11,7.54c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M13.36,6.76c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M15.59,7.54c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M17.84,6.76c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M20.09,7.54c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M22.32,6.76c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M24.57,7.54c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M26.82,6.76c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M29.05,7.54c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M31.3,6.76c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M33.54,7.54c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M35.78,6.76c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M38.02,7.54c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M40.27,6.76c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M42.5,7.54c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M44.75,6.76c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M47,7.54c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M49.23,6.76c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M51.48,7.54c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M53.73,6.76c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M55.96,7.54c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M58.21,6.76c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M60.45,7.54c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M62.69,6.76c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M64.93,7.54c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M67.18,6.76c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M69.42,7.54c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M71.66,6.76c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4c0-.22-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M73.9,7.54c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M76.14,6.76c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M78.38,7.54c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M80.63,6.76c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M82.87,7.54c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M85.11,6.76c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M87.36,7.54c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M89.59,6.76c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M91.84,7.54c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M94.09,6.76c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M96.32,7.54c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M98.57,6.76c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M100.81,7.54c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M103.05,6.76c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M105.29,7.54c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M107.54,6.76c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M109.78,7.54c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M112.02,6.76c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M114.27,7.54c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M116.5,6.76c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M118.75,7.54c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M121,6.76c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M123.23,7.54c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M125.48,6.76c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M127.72,7.54c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M129.96,6.76c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M132.21,7.54c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M134.45,6.76c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4c0-.22-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M136.69,7.54c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M138.93,6.76c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M141.17,7.54c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M143.41,6.76c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M145.66,7.54c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M4.38,5.29c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4c0,.22.17.4.4.4Z"></path>
      <path className="cls-2" d="M6.63,4.5c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M8.87,5.29c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M11.11,4.5c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M13.36,5.29c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M15.59,4.5c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M17.84,5.29c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M20.09,4.5c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M22.32,5.29c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M24.57,4.5c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M26.82,5.29c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M29.05,4.5c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M31.3,5.29c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M33.54,4.5c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M35.78,5.29c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M38.02,4.5c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M40.27,5.29c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M42.5,4.5c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M44.75,5.29c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M47,4.5c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M49.23,5.29c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M51.48,4.5c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M53.73,5.29c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M55.96,4.5c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M58.21,5.29c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M60.45,4.5c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4c0-.23-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M62.69,5.29c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M64.93,4.5c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M67.18,5.29c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4c0,.22.17.4.4.4Z"></path>
      <path className="cls-2" d="M69.42,4.5c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M71.66,5.29c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M73.9,4.5c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M76.14,5.29c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M78.38,4.5c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M80.63,5.29c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M82.87,4.5c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4c0-.23-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M85.11,5.29c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M87.36,4.5c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M89.59,5.29c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4c0,.22.17.4.4.4Z"></path>
      <path className="cls-2" d="M91.84,4.5c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M94.09,5.29c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M96.32,4.5c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M98.57,5.29c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M100.81,4.5c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M103.05,5.29c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M105.29,4.5c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M107.54,5.29c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M109.78,4.5c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M114.27,4.5c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M112.02,4.5c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M116.5,5.29c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M118.75,4.5c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M121,5.29c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M123.23,4.5c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M125.48,5.29c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M127.72,4.5c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M129.97,5.29c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4c0,.22.17.4.4.4Z"></path>
      <path className="cls-2" d="M132.21,4.5c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M134.45,5.29c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M136.69,4.5c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M138.93,5.29c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M141.17,4.5c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M143.42,5.29c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M145.66,4.5c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4c0-.23-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M4.38,2.27c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M6.63,3.05c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M8.87,2.27c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4c0-.23-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M11.11,3.05c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M13.36,2.27c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M15.59,3.05c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4c0,.22.17.4.4.4Z"></path>
      <path className="cls-2" d="M17.84,2.27c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M20.09,3.05c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M22.32,2.27c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M24.57,3.05c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M26.82,2.27c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M29.05,3.05c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M31.3,2.27c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M33.54,3.05c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M35.78,2.27c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M38.02,3.05c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M40.27,2.27c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M42.5,3.05c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M44.75,2.27c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M47,3.05c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M49.23,2.27c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M51.48,3.05c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M53.73,2.27c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M55.96,3.05c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M58.21,2.27c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M60.45,3.05c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M62.69,2.27c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M64.93,3.05c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M67.18,2.27c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M69.42,3.05c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M71.66,2.27c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4c0-.23-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M73.9,3.05c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M76.14,2.27c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M78.38,3.05c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4c0,.22.17.4.4.4Z"></path>
      <path className="cls-2" d="M80.63,2.27c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M82.87,3.05c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M85.11,2.27c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M87.36,3.05c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M89.59,2.27c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M91.84,3.05c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M94.09,2.27c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M96.32,3.05c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M98.57,2.27c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M100.81,3.05c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M103.05,2.27c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M105.29,3.05c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M107.54,2.27c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M109.78,3.05c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M112.02,2.27c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M114.27,3.05c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M116.5,2.27c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M118.75,3.05c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M121,2.27c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M123.23,3.05c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M125.48,2.27c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M127.72,3.05c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M129.96,2.27c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M132.21,3.05c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M134.45,2.27c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4c0-.23-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M136.69,3.05c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4c0,.22.17.4.4.4Z"></path>
      <path className="cls-2" d="M138.93,2.27c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M141.17,3.05c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4c0,.22.17.4.4.4Z"></path>
      <path className="cls-2" d="M143.41,2.27c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M145.66,3.05c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M4.38.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M6.63,0c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4S6.86,0,6.63,0Z"></path>
      <path className="cls-2" d="M8.87.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M11.11,0c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4S11.34,0,11.11,0Z"></path>
      <path className="cls-2" d="M13.36.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M15.59,0c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4S15.82,0,15.59,0Z"></path>
      <path className="cls-2" d="M17.84.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M20.09,0c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M22.32.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M24.57,0c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M26.82.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M29.05,0c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M31.3.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M33.54,0c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M35.78.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M38.02,0c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M40.27.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M42.5,0c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M44.75.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M47,0c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M49.23.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M51.48,0c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M53.73.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M55.96,0c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M58.21.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M60.45,0c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M62.69.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M64.93,0c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M67.18.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M69.42,0c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M71.66.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M73.9,0c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M76.14.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M78.38,0c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M80.63.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M82.87,0c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M85.11.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M87.36,0c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M89.59.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M91.84,0c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M94.09.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M96.32,0c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M98.57.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M100.81,0c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M103.05.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M105.29,0c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M107.54.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M109.78,0c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M114.27,0c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M112.02,0c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M116.5.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M118.75,0c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M121,.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M123.23,0c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M125.48.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M127.72,0c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M129.97.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M132.21,0c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M134.45.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M136.69,0c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M138.93.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M141.17,0c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
      <path className="cls-2" d="M143.42.79c.22,0,.4-.17.4-.4s-.17-.4-.4-.4-.4.17-.4.4.17.4.4.4Z"></path>
      <path className="cls-2" d="M145.66,0c-.22,0-.4.17-.4.4s.17.4.4.4.4-.17.4-.4-.17-.4-.4-.4Z"></path>
  </svg>
)

export default LocksBridge
